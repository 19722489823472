import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const FeaturedOn = ({ prefix }) => {
  return (
    <section className="relative">
      <aside className="text-center">
        {(prefix == "au" || prefix == "uk" || prefix == "fr-ca") && (
          <h3 className="mb-30 text-lg font-bold text-333333">
            {prefix === "fr-ca" ? "Comme présenté sur." : "As featured on."}
          </h3>
        )}
        <div className="width__container flex flex-wrap items-center justify-center gap-x-20">
          {prefix == "au" || prefix == "uk" ? (
            <>
              <div className="p-15 max500:p-30">
                <span className="flex justify-center">
                  <StaticImage
                    className="w-120 scale-95"
                    src="../../images/featured-on/smhLogo.webp"
                    alt="Men's Health Clinic Featured on: The Sydney Morning Herald"
                  />
                </span>
              </div>
              <div className="p-15 max500:p-30">
                <span className="flex justify-center">
                  <StaticImage
                    className="w-120 scale-95"
                    src="../../images/featured-on/bikeLogo.webp"
                    alt="Men's Health Clinic Featured on: Bike Magazine"
                  />
                </span>
              </div>
              <div className="p-15 max500:p-30">
                <span className="flex justify-center">
                  <StaticImage
                    className="w-120 scale-95"
                    src="../../images/featured-on/2uefmLogo.webp"
                    alt="Men's Health Clinic Featured on: 2UE 954 Sydney"
                  />
                </span>
              </div>
              <div className="p-15 max500:p-30">
                <span className="flex justify-center">
                  <StaticImage
                    className="w-120 scale-95"
                    src="../../images/featured-on/2gbfmLogo.webp"
                    alt="Men's Health Clinic Featured on: 2GB 873AM"
                  />
                </span>
              </div>
              <div className="p-15 max500:p-30">
                <span className="flex justify-center">
                  <StaticImage
                    className="w-120 scale-95"
                    src="../../images/featured-on/kiisfmLogo.webp"
                    alt="Men's Health Clinic Featured on: KIIS 1065"
                  />
                </span>
              </div>
            </>
          ) : null}
          {(prefix === "ca" || prefix === "fr-ca") && (
            <div className="p-15 max500:p-30">
              <span className="flex flex-col justify-center">
                <StaticImage
                  className="w-120 scale-95"
                  src="../../images/homepage/bbb.webp"
                  alt="BBB Logo"
                />
                <span
                  lang="en"
                  style={{
                    color: "#015A75",
                    fontSize: "0.875rem",
                    fontWeight: "bold",
                    fontFamily: "Arial, Helvetica, sans-serif",
                  }}
                >
                  BBB RATING: A+
                </span>
              </span>
            </div>
          )}
          <div className="p-15 max500:p-30">
            <span className="flex justify-center">
              <StaticImage
                className="w-120 scale-95"
                src="../../images/homepage/legitScript.webp"
                alt="LegitScript Logo"
              />
            </span>
          </div>
          {(prefix === "ca" || prefix === "fr-ca") && (
            <>
            <div className="max500:p-30">
              <span className="flex justify-center">
                <StaticImage
                  className="w-120 scale-95"
                  src="../../images/homepage/cbrb.webp"
                  alt="CBRB Logo"
                />
              </span>
            </div>
            <div className="max500:p-30">
              <span className="flex justify-center">
                <StaticImage
                  className="w-120 scale-95"
                  src="../../images/homepage/proudly_canadian.webp"
                  alt="Proudly Canadian Logo"
                />
              </span>
            </div>
            </>
          )}
          {prefix === "uk" && (
            <div className="p-15 max500:p-30">
              <span className="flex justify-center">
                <StaticImage
                  className="w-120 scale-95"
                  src="../../images/homepage/cqcPurple.webp"
                  alt="CQC Logo"
                />
              </span>
            </div>
          )}
        </div>
      </aside>
    </section>
  )
}
